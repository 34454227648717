import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import Layout from './components/Layout';
import Login from './pages/login';
import { useStateContext } from './contexts/ContextProvider';
import { LanguageContext } from './contexts/LanguageContext';
import Top from './pages/top';
import AppointmentList from './pages/appointment-list';
import AppointmentHistory from './pages/appointment-history';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './pages/404';
import LoginForm from './components/loginPrompt';
import BasicLogin from './pages/basic-login';

const App = () => {
  const { currentMode } = useStateContext();
  const [language, setLanguage] = useState('english');

  function toggleLanguage() {
    setLanguage(() => (language === 'english' ? 'japanese' : 'english'));
  }

  useEffect(() => {
    const currentLanguageMode = localStorage.getItem('languageMode');
    if (currentLanguageMode) {
      setLanguage(currentLanguageMode);
    }
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <LoginForm />
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <LanguageContext.Provider value={{ language, toggleLanguage }}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={(<Login />)} />
            <Route path="/auth" element={(<BasicLogin />)} />
            <Route element={(<ProtectedRoute />)}>
              <Route element={(<Layout />)}>
                {/* dashboard  */}
                <Route path="/" element={<Top />} />
                <Route path="/visitor-list/:id" element={<AppointmentList />} />
                <Route path="/visitor-history/:id" element={<AppointmentHistory />} />
              </Route>
            </Route>
            <Route path="*" exact element={<NotFound />} />
          </Routes>

        </BrowserRouter>
      </LanguageContext.Provider>
    </div>
  );
};

export default App;
