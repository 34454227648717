import { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../../data/translations.js';

function AltText(text) {
  const { language } = useContext(LanguageContext);

  return translations[language][text];
}

export default AltText;

