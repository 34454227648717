export const translations = {
  english: {
    SignInToYourAccount: 'Sign in to your account',
    EmailAddress: 'Email Address',
    Password: 'Password',
    RememberMe: 'Remember Me',
    SignIn: 'Sign In',
    AppointmentTime: 'Appointment Time',
    VisitorName: 'Visitor Name',
    Top: 'Top',
    VisitorList: 'Visitor List',
    VisitorHistory: 'Visitor History',
    Visitors: 'Visitors',
    Dashboard: 'Dashboard',
    Hi: 'Hi',
    Logout: 'Logout',
    UserProfile: 'User Profile',
    AddNewVisitor: 'Add New Visitor',
    All: 'All',
    ThisMonth: 'This Month',
    ThisWeek: 'This Week',
    Today: 'Today',
    VisitorCompanyName: 'Visitor Company Name',
    Purpose: 'Purpose',
    StaffName: 'Staff Name',
    ReceptionNumber: 'Reception #',
    Title: 'Title',
    Details: 'Details',
    CompanyName: 'Company Name',
    Address: 'Address',
    NumberOfEmployees: 'Number of Employees',
    Location: 'Location',
    NumberOfUsers: 'Number of Users',
    NumberOfVisitors: 'Number of Visitors',
    VisitorsToday: 'Visitors Today',
    VisitorsThisWeek: 'Visitors this Week',
    VisitorsThisMonth: 'Visitor this Month',
    Total: 'Total',
    Interview: 'Interview',
    Meeting: 'Meeting',
    FoodDelivery: 'Food Delivery',
    Askul: 'Askul',
    Logistic: 'Logistic',
    PostOffice: 'Post Office',
    Other: 'Other',
    CreateNewAppointment: 'Create New Appointment',
    Close: 'Close',
    ChooseAnOption: 'Choose an option',
    NewAppointmentWasCreated: 'New Appointment was created.',
    TheReceptionNumberIs: 'The Reception Number is',
  },
  japanese: {
    SignInToYourAccount: 'ログイン',
    EmailAddress: 'メールアドレス',
    Password: 'パスワード',
    RememberMe: 'メールアドレス/パスワードを保存する',
    SignIn: 'ログイン',
    AppointmentTime: '訪問（来社）時間',
    VisitorName: '訪問者名',
    Top: 'Top',
    VisitorList: '訪問者リスト',
    VisitorHistory: '訪問履歴',
    Visitors: '訪問者',
    Dashboard: 'ダッシュボード',
    Hi: 'Hi',
    Logout: 'ログアウト',
    UserProfile: 'ユーザープロフィール',
    AddNewVisitor: '新しい訪問者を追加',
    All: 'ALL',
    ThisMonth: '今月',
    ThisWeek: '今週',
    Today: '今日',
    VisitorCompanyName: '訪問者社名',
    Purpose: '目的',
    StaffName: 'スタッフ名',
    ReceptionNumber: '受付番号',
    Title: 'タイトル',
    Details: '詳細',
    CompanyName: '会社名',
    Address: '住所',
    NumberOfEmployees: '従業員数',
    Location: '設置場所',
    NumberOfUsers: 'ユーザー数',
    NumberOfVisitors: '訪問者数',
    VisitorsToday: '今日の訪問者数',
    VisitorsThisWeek: '今週の訪問者数',
    VisitorsThisMonth: '今月の訪問者数',
    Total: '合計',
    Interview: '面接',
    Meeting: '打ち合わせ',
    FoodDelivery: 'フードデリバリー',
    Askul: 'アスクル',
    Logistic: '配達業者',
    PostOffice: '郵便局',
    Other: 'その他',
    CreateNewAppointment: '新規予約作成',
    Close: '閉じる',
    ChooseAnOption: 'オプションを選択',
    NewAppointmentWasCreated: '新しい予約が作成されました',
    TheReceptionNumberIs: '受付番号は',
  },
};

