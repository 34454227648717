import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import {
  AiOutlineDelete,
} from 'react-icons/ai';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import jwt from 'jwt-decode';
import AltText from '../components/Language/AltText';
import NewVisitor from '../components/NewVisitor';
// import { LanguageContext } from '../contexts/LanguageContext';
import { translations } from '../data/translations';
import { LanguageContext } from '../contexts/LanguageContext';

const AppointmentList = () => {
  // use state to filter rows on button click and display the filtered data
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `${token}`,
  };
  const { id } = useParams();

  const [companyId, setCompanyId] = useState(id);

  const [filteredRows, setFilteredRows] = useState([]);
  const [data, setData] = useState([]);
  const [openTab, setOpenTab] = React.useState(4);
  const [selectedIds, setSelectedIds] = useState([]);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const fR = filteredRows.map((item) => ({
      ...item,
      purpose: translations[language][item.purposeId],
    }));

    const dR = data.map((item) => ({
      ...item,
      purpose: translations[language][item.purposeId],
    }));

    setFilteredRows(fR);
    setData(dR);
  }, [AltText('AppointmentTime')]);

  useEffect(() => {
    setCompanyId(id);
    const user = jwt(token);
    const pathRole = `${process.env.REACT_APP_SERVER_URL}/user/role/${id}/${user.userId}`;
    const fetchData = async () => {
      try {
        await axios.get(pathRole, { headers }).then((res) => {
          const output = res.data.user;
          if (!output) {
            window.location.assign('/not-found');
          }
        });
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchData();
  }, [id]);

  const handleDelete = async () => {
    if (selectedIds.length === 0) {
      toast.error('Please select the rows to delete');
      return;
    }
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/visitor`, { headers, data: { ids: selectedIds } });
      toast.success('Deleted Successfully');
      const newData = data.filter((item) => !selectedIds.includes(item.id));
      setData(newData);
      setFilteredRows(newData);
      setSelectedIds([]);
    } catch (error) {
      toast.error('Error Deleting the rows');
    }
  };

  const handleDeleteSubmit = async () => {
    const confirm = window.confirm('Are you sure you want to delete the selected rows?');
    if (confirm) {
      await handleDelete();
    }
  };

  useEffect(() => {
    const path = `${process.env.REACT_APP_SERVER_URL}/visitor/${companyId}`;

    const fetchData = async () => {
      try {
        await axios.get(path, { headers }).then((res) => {
          setData(res.data);
          setFilteredRows(res.data);
        });
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchData();
  }, [companyId]);

  // Filter rows based on button click
  function filterRows(value, type) {
    setOpenTab(value);
    if (type === 'today') {
      const currentDate = new Date();

      const filteredArray = data.filter((obj) => {
        const objDate = new Date(obj.appointmentTime);
        const isToday = objDate.getDate() === currentDate.getDate()
            && objDate.getMonth() === currentDate.getMonth()
            && objDate.getFullYear() === currentDate.getFullYear();
        return isToday;
      });
      setFilteredRows(filteredArray);
    } else if (type === 'week') {
      // filter based on date to get upcoming week data
      const today = new Date();
      const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1);
      const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 7);

      const currentWeekRecords = data.filter((record) => {
        const recordDate = new Date(record.appointmentTime);
        return recordDate >= startOfWeek && recordDate <= endOfWeek;
      });
      setFilteredRows(currentWeekRecords);
    } else if (type === 'month') {
      const currentMonthRecords = data.filter((record) => {
        const recordDate = new Date(record.appointmentTime);
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        const year = recordDate.getFullYear();
        return recordDate.getMonth() + 1 === currentMonth && year === currentYear;
      });
      setFilteredRows(currentMonthRecords);
    } else {
      setFilteredRows(data);
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    {
      field: 'appointmentTime',
      headerName: AltText('AppointmentTime'),
      type: 'date',
      flex: 1.5,
      editable: false,
    },
    {
      field: 'name',
      headerName: AltText('VisitorName'),
      flex: 1,
      editable: false,
    },
    {
      field: 'visitorCompanyName',
      headerName: AltText('VisitorCompanyName'),
      flex: 1.5,
      editable: false,
    },
    {
      field: 'purpose',
      headerName: AltText('Purpose'),
      flex: 1,
      editable: false,
    },
    {
      field: 'staffName',
      headerName: AltText('StaffName'),
      flex: 1,
      editable: false,
    },
    {
      field: 'receptionNumber',
      headerName: AltText('ReceptionNumber'),
      flex: 1,
      editable: false,
    },
  ];

  return (
    <div className="flex flex-col m-4 p-4">
      <div className="flex flex-row justify-between">
        <h1 className="text-2xl font-bold">{AltText('VisitorList')}</h1>
        <NewVisitor id={companyId} />
      </div>

      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="flex flex-row justify-between">
            {/* Delete buttons */}
            <Tooltip title={<p style={{ fontSize: '12px' }}>Delete Selected rows</p>} arrow>
              <button
                className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleDeleteSubmit}
              >
                <AiOutlineDelete className="inline-block" />
              </button>
            </Tooltip>

            {/* Filter buttons */}
            <div className="overflow-hidden flex flex-row-reverse">
              <button
                type="button"
                className={`text-white py-2 px-4 rounded ml-2 mb-2 flex flex-row${
                  openTab === 1
                    ? ' bg-green-500 hover:bg-green-700'
                    : ' bg-[#1E4DB7] hover:bg-[#344873]'
                }`}
                onClick={() => filterRows(1, 'today')}
              >
                {AltText('Today')}
              </button>
              <button
                type="button"
                className={`text-white py-2 px-4 rounded ml-2 mb-2 flex flex-row${
                  openTab === 2
                    ? ' bg-green-500 hover:bg-green-700'
                    : ' bg-[#1E4DB7] hover:bg-[#344873]'
                }`}
                onClick={() => filterRows(2, 'week')}
              >
                {AltText('ThisWeek')}
              </button>
              <button
                type="button"
                className={`text-white py-2 px-4 rounded ml-2 mb-2 flex flex-row${
                  openTab === 3
                    ? ' bg-green-500 hover:bg-green-700'
                    : ' bg-[#1E4DB7] hover:bg-[#344873]'
                }`}
                onClick={() => filterRows(3, 'month')}
              >
                {AltText('ThisMonth')}
              </button>
              <button
                type="button"
                className={`text-white py-2 px-4 rounded ml-2 mb-2 flex flex-row${
                  openTab === 4
                    ? ' bg-green-500 hover:bg-green-700'
                    : ' bg-[#1E4DB7] hover:bg-[#344873]'
                }`}
                onClick={() => filterRows(4, 'all')}
              >
                {AltText('All')}
              </button>
            </div>
          </div>

          <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onSelectionModelChange={(ids) => {
                setSelectedIds(ids);
              }}
              keepNonExistentRowsSelected
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AppointmentList;
