import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import jwt from 'jwt-decode';
import AltText from '../components/Language/AltText';
import { translations } from '../data/translations';
import { LanguageContext } from '../contexts/LanguageContext';

const AppointmentHistory = () => {
  // use state to filter rows on button click and display the filtered data
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `${token}`,
  };
  const { id } = useParams();
  const [companyId, setCompanyId] = useState(id);

  const [data, setData] = useState([]);

  const [filteredRows, setFilteredRows] = useState(data);
  const [openTab, setOpenTab] = React.useState(4);

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    setCompanyId(id);
    const user = jwt(token);
    const pathRole = `${process.env.REACT_APP_SERVER_URL}/user/role/${id}/${user.userId}`;
    const fetchData = async () => {
      try {
        await axios.get(pathRole, { headers }).then((res) => {
          const output = res.data.user;
          if (!output) {
            window.location.assign('/not-found');
          }
        });
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fR = filteredRows.map((item) => ({
      ...item,
      purpose: translations[language][item.purposeId.replace(/\s+/g, '')],
    }));

    const dR = data.map((item) => ({
      ...item,
      purpose: translations[language][item.purposeId.replace(/\s+/g, '')],
    }));

    setFilteredRows(fR);
    setData(dR);
  }, [AltText('AppointmentTime')]);

  useEffect(() => {
    const path = `${process.env.REACT_APP_SERVER_URL}/visitor/history/${companyId}`;

    const fetchData = async () => {
      try {
        await axios.get(path, { headers }).then((res) => {
          console.log(res.data);
          setData(res.data);
          setFilteredRows(res.data);
        });
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchData();
  }, [companyId]);

  // Filter rows based on button click
  function filterRows(value, type) {
    setOpenTab(value);
    if (type === 'today') {
      const currentDate = new Date();

      const filteredArray = data.filter((obj) => {
        const objDate = new Date(obj.appointmentTime);
        const isToday = objDate.getDate() === currentDate.getDate()
            && objDate.getMonth() === currentDate.getMonth()
            && objDate.getFullYear() === currentDate.getFullYear();
        return isToday;
      });
      setFilteredRows(filteredArray);
    } else if (type === 'week') {
      // filter based on date to get upcoming week data
      const today = new Date();
      const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1);
      const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 7);

      console.log(startOfWeek, endOfWeek);

      const currentWeekRecords = data.filter((record) => {
        const recordDate = new Date(record.appointmentTime);
        return recordDate >= startOfWeek && recordDate <= endOfWeek;
      });
      setFilteredRows(currentWeekRecords);
    } else if (type === 'month') {
      const currentMonthRecords = data.filter((record) => {
        const recordDate = new Date(record.appointmentTime);
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        const year = recordDate.getFullYear();
        return recordDate.getMonth() + 1 === currentMonth && year === currentYear;
      });
      setFilteredRows(currentMonthRecords);
    } else {
      setFilteredRows(data);
    }
  }

  const columns = [
    {
      field: 'appointmentTime',
      headerName: AltText('AppointmentTime'),
      type: 'date',
      flex: 1.5,
      editable: false,
    },
    {
      field: 'visitorName',
      headerName: AltText('VisitorName'),
      flex: 1,
      editable: false,
    },
    {
      field: 'visitorCompanyName',
      headerName: AltText('VisitorCompanyName'),
      flex: 1.5,
      editable: false,
    },
    {
      field: 'purpose',
      headerName: AltText('Purpose'),
      flex: 1,
      editable: false,
    },
    {
      field: 'staffName',
      headerName: AltText('StaffName'),
      flex: 1,
      editable: false,
    },
    {
      field: 'receptionNumber',
      headerName: AltText('ReceptionNumber'),
      flex: 1,
      editable: false,
    },
  ];

  return (
    <div className="flex flex-col m-4 p-4">
      <div className="flex flex-row justify-between">
        <h1 className="text-2xl font-bold">{AltText('VisitorHistory')}</h1>

      </div>

      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

          {/* Filter buttons */}
          <div className="overflow-hidden flex flex-row-reverse">
            <button
              type="button"
              className={`text-white py-2 px-4 rounded ml-2 mb-2 flex flex-row${
                openTab === 1
                  ? ' bg-green-500 hover:bg-green-700'
                  : ' bg-[#1E4DB7] hover:bg-[#344873]'
              }`}
              onClick={() => filterRows(1, 'today')}
            >
              {AltText('Today')}
            </button>
            <button
              type="button"
              className={`text-white py-2 px-4 rounded ml-2 mb-2 flex flex-row${
                openTab === 2
                  ? ' bg-green-500 hover:bg-green-700'
                  : ' bg-[#1E4DB7] hover:bg-[#344873]'
              }`}
              onClick={() => filterRows(2, 'week')}
            >
              {AltText('ThisWeek')}
            </button>
            <button
              type="button"
              className={`text-white py-2 px-4 rounded ml-2 mb-2 flex flex-row${
                openTab === 3
                  ? ' bg-green-500 hover:bg-green-700'
                  : ' bg-[#1E4DB7] hover:bg-[#344873]'
              }`}
              onClick={() => filterRows(3, 'month')}
            >
              {AltText('ThisMonth')}
            </button>
            <button
              type="button"
              className={`text-white py-2 px-4 rounded ml-2 mb-2 flex flex-row${
                openTab === 4
                  ? ' bg-green-500 hover:bg-green-700'
                  : ' bg-[#1E4DB7] hover:bg-[#344873]'
              }`}
              onClick={() => filterRows(4, 'all')}
            >
              {AltText('All')}
            </button>
          </div>

          <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              pageSize={20}
              disableSelectionOnClick
              rowsPerPageOptions={[20]}
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AppointmentHistory;
