import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

const BasicLogin = () => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { username: 'PlusW', password: '138Nfo83nO(Fn23873%' };
    // validate username and password with above data

    if (username === data.username && password === data.password) {
      // set the local session
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      localStorage.setItem('session', expirationDate.toString());
      window.location.href = '/';
    } else {
      toast.error('Incorrect Credentials');
    }
  };
  return (
  //   Create a simple form with username and password in tailwind
    <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-50 sm:px-6 lg:px-8">
      <div
        className="flex flex-col items-center justify-center w-full max-w-md p-6 space-y-4 bg-white border-2 border-gray-300 rounded-md shadow-md"
      >
        <h1 className="text-3xl font-bold text-gray-900">System Login</h1>
        <form
          className="flex flex-col w-full space-y-4"
          method="POST"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="flex flex-col space-y-1">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="username" className="text-sm font-medium text-gray-700">Id</label>
            <input
              type="text"
              id="username"
              name="username"
              className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              required
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col space-y-1">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="password" className="text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              required
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Login
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>

  );
};

export default BasicLogin;
