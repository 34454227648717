import React from 'react';

const LoginForm = () => {
  // const validCredentials = 'myusername:mypassword';
  //
  // let credentials = '';
  // let logic = true;
  const handleLogin = () => {
    // while (logic) {
    //   credentials = window.prompt(
    //     'Enter your username and password, separated by a colon (e.g. username:password):',
    //   );
    //
    //   if (credentials !== validCredentials) {
    //     alert('Invalid username or password. Please try again.');
    //   } else {
    //     alert('Login Successful');
    //     logic = false;
    //   }
    //
    //   alert(credentials + validCredentials + (credentials === validCredentials) + logic);
    // }
  };

  handleLogin();

  return (
    <div />
  );
};

export default LoginForm;
