import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';

import jwt from 'jwt-decode';
import { Button } from '.';
import userPic from '../data/user-pic.png';
import { useStateContext } from '../contexts/ContextProvider';
import AltText from './Language/AltText';

const UserProfile = () => {
  const token = localStorage.getItem('token');
  const user = jwt(token);
  const { currentColor } = useStateContext();
  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">{AltText('UserProfile')}</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={userPic}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200"> {`${user.name} ${user.familyName}`} </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">  {user.roleId === 1 ? 'Admin' : 'Super Admin'}   </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> {user.email} </p>
        </div>
      </div>
      <div className="mt-5">
        <Button
          color="white"
          bgColor={currentColor}
          text={AltText('Logout')}
          borderRadius="10px"
          width="full"
          logout="true"
        />
      </div>
    </div>

  );
};

export default UserProfile;
