import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineCancel, MdOutlineHistory, MdOutlineHome, MdOutlineList } from 'react-icons/md';

import jwt from 'jwt-decode';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useStateContext } from '../contexts/ContextProvider';
import AltText from './Language/AltText';

const Sidebar = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const path = `${process.env.REACT_APP_SERVER_URL}/company/menu`;
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `${token}`,
    };

    const fetchData = async () => {
      try {
        await axios.get(path, { headers }).then((res) => {
          setData(res.data);
        });
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchData();
  }, []);
  const [open, setOpen] = React.useState(false);

  const updateOpen = () => {
    setOpen(!open);
  };

  const [open1, setOpen1] = React.useState(false);

  const updateOpen1 = () => {
    setOpen1(!open1);
  };
  const { currentColor, activeMenu, setActiveMenu, screenSize } = useStateContext();

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };
  const token = localStorage.getItem('token');
  if (!token) {
    window.location.assign('/login');
  }
  const user = jwt(token);

  // const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
  // const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';

  const openClass = open ? 'block py-2 space-y-2' : 'hidden py-2 space-y-2';
  const openClass1 = open1 ? 'block py-2 space-y-2' : 'hidden py-2 space-y-2';

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={handleCloseSideBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              <span>{user.companyName}</span>
            </Link>
            <button
              type="button"
              onClick={() => setActiveMenu(!activeMenu)}
              style={{ color: currentColor }}
              className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
            >
              <MdOutlineCancel />
            </button>
          </div>
          <div className="mt-5 ">
            <div className="h-full px-3 py-4 overflow-y-auto dark:bg-gray-800">
              <ul className="space-y-2">
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    to="/"
                    className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <MdOutlineHome />
                    <span className="ml-3">{AltText('Top')}</span>
                  </Link>
                </li>
                <li>
                  <button
                    type="button"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    aria-controls="dropdown-example"
                    data-collapse-toggle="dropdown-example"
                    onClick={updateOpen}
                  >
                    <MdOutlineList />
                    <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>{AltText('VisitorList')}</span>
                    <svg
                      sidebar-toggle-item
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <ul id="dropdown-example" className={openClass}>
                    {
                      data.map((item) => (
                        <li key={item.companyId}>
                          <Link
                            to={`/visitor-list/${item.companyId}`}
                            onClick={handleCloseSideBar}
                            className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                          >
                            {item.companyName}
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </li>
                <li>
                  <button
                    type="button"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    aria-controls="dropdown-example"
                    data-collapse-toggle="dropdown-example"
                    onClick={updateOpen1}
                  >
                    <MdOutlineHistory />
                    <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>{AltText('VisitorHistory')}</span>
                    <svg
                      sidebar-toggle-item
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <ul id="dropdown-example" className={openClass1}>
                    {
                        data.map((item) => (
                          <li key={item.companyId}>
                            <Link
                              to={`/visitor-history/${item.companyId}`}
                              onClick={handleCloseSideBar}
                              className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                            >
                              {item.companyName}
                            </Link>
                          </li>
                        ))
                      }
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
