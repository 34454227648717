import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import LanguageButton from '../components/Language/LanguageButton';
import AltText from '../components/Language/AltText';

const Login = () => {
  useEffect(() => {
    const expirationDate = localStorage.getItem('session');
    if (new Date(expirationDate) <= new Date()) {
      // Use the data
      localStorage.removeItem('session');
      window.location.href = '/auth';
    }
  });
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  const lang = localStorage.getItem('language') || 'japanese';

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { email, password };
    const url = `${process.env.REACT_APP_SERVER_URL}/user/login`;
    try {
      const result = await axios.post((url), data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (result.status === 200) {
        localStorage.setItem('token', result.data.token);
        if (!localStorage.getItem('language')) localStorage.setItem('language', result.data.language);
        toast.success('Login Successful');
        navigate('/');
      } else {
        toast.error('Incorrect Credentials');
      }
    } catch (error) {
      if (error.response.status === 404) toast.error('Incorrect Credentials');
      else toast.error(error.message);
    }
  };
  return (
    <div>
      <div className="py-4 flex flex-row-reverse justify-between">
        <LanguageButton lang={lang} />

        <div
          className="items-center gap-3 ml-6 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
        >
          <span>Reception System</span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center py-24 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div>
            {/* <img className="w-auto h-12 mx-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" /> */}
            <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
              {AltText('SignInToYourAccount')}
            </h2>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <form
                className="space-y-6"
                action="#"
                method="POST"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder={AltText('EmailAddress')}
                    />
                  </div>
                  <div>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder={AltText('Password')}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                      {AltText('RememberMe')}
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#1E4DB7] hover:bg-[#2753b7] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {AltText('SignIn')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Login;
