import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AltText from '../components/Language/AltText';

const Top = () => {
  // Create json object to display company information such as name, address, number of empoloyess, location and then number of vistors for week, month and year
  // fetch data from api and display it in the table
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `${token}`,
  };

  const [data, setData] = useState([]);
  const [openTab, setOpenTab] = React.useState(0);
  const [selectedData, setSelectedData] = React.useState([]);

  useEffect(() => {
    const path = `${process.env.REACT_APP_SERVER_URL}/company`;
    const fetchData = async () => {
      try {
        await axios.get(path, { headers }).then((res) => {
          setData(res.data);
          setOpenTab(res.data[0]?.companyId ?? 0);
          setSelectedData([res.data[0]] ?? []);
        });
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  const handleTabChange = (id) => {
    const newData = data.filter((item) => item.companyId === id);
    setSelectedData(newData);
  };

  return (
    <div className="m-10">
      <div className="flex flex-row justify-between pl-2">
        <div className="overflow-hidden flex flex-row">
          {
            data?.map((item) => (

              <button
                type="button"
                className={`text-white py-2 px-4 rounded ml-2 mb-2 flex flex-row${
                  openTab === item.companyId
                    ? ' bg-green-500 hover:bg-green-700'
                    : ' bg-[#1E4DB7] hover:bg-[#344873]'
                }`}
                onClick={() => { setOpenTab(item.companyId); handleTabChange(item.companyId); }}
              >
                {item.companyName}
              </button>
            ))
          }

        </div>
      </div>
      {selectedData?.map((item) => (
        <div>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg m-4">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    {AltText('Title')}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {AltText('Details')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {AltText('CompanyName')}
                  </th>
                  <td className="px-6 py-4">
                    {item.companyName}
                  </td>

                </tr>
                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {AltText('Address')}
                  </th>
                  <td className="px-6 py-4">
                    {item.address}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {AltText('NumberOfEmployees')}
                  </th>
                  <td className="px-6 py-4">
                    {item.numberOfEmployees}
                  </td>
                </tr>
                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {AltText('Location')}
                  </th>
                  <td className="px-6 py-4">
                    {item.location}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {AltText('NumberOfUsers')}
                  </th>
                  <td className="px-6 py-4">
                    {item.numberOfUsers}
                  </td>
                </tr>
                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {AltText('NumberOfVisitors')}
                  </th>
                  <td className="px-6 py-4">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            #
                          </th>
                          <th scope="col" className="px-6 py-3">
                            {AltText('VisitorsToday')}
                          </th>
                          <th scope="col" className="px-6 py-3">
                            {AltText('VisitorsThisWeek')}
                          </th>
                          <th scope="col" className="px-6 py-3">
                            {AltText('VisitorsThisMonth')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {AltText('Total')}
                          </th>
                          <th className="px-6 py-4">
                            {item.numberOfVisitors.daily.total}
                          </th>
                          <th className="px-6 py-4">
                            {item.numberOfVisitors.weekly.total}
                          </th>
                          <th className="px-6 py-4">
                            {item.numberOfVisitors.monthly.total}
                          </th>
                        </tr>
                        <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {AltText('Interview')}
                          </th>
                          <td className="px-6 py-4">
                            {item.numberOfVisitors.daily.interview}
                          </td>
                          <td className="px-6 py-4">
                            {item.numberOfVisitors.weekly.interview}
                          </td>
                          <td className="px-6 py-4">
                            {item.numberOfVisitors.monthly.interview}
                          </td>
                        </tr>
                        <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {AltText('Meeting')}
                          </th>
                          <td className="px-6 py-4">
                            {item.numberOfVisitors.daily.meeting}
                          </td>
                          <td className="px-6 py-4">
                            {item.numberOfVisitors.weekly.meeting}
                          </td>
                          <td className="px-6 py-4">
                            {item.numberOfVisitors.monthly.meeting}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>

  );
};

export default Top;
