import React from 'react';

// Not found fancy page using tailwind css
const NotFound = () => (
  <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100 sm:px-6 lg:px-8">
    <div className="flex flex-col items-center justify-center w-full space-y-8">
      <div className="flex flex-col items-center justify-center space-y-2">
        <div className="text-9xl font-bold text-gray-400">404</div>
        <div className="text-2xl font-bold text-gray-400">Page not found</div>
      </div>
      <div className="flex flex-col items-center justify-center space-y-2">
        <div className="text-xl font-bold text-gray-400">The page you are looking for does not exist.</div>
        <div className="text-xl font-bold text-gray-400">You may have mistyped the address or the page may have moved.</div>
      </div>
    </div>
  </div>
);

export default NotFound;
