import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const ProtectedRoute = () => {
  useEffect(() => {
    const expirationDate = localStorage.getItem('session');
    if (new Date(expirationDate) <= new Date()) {
      // Use the data
      localStorage.removeItem('session');
      window.location.href = '/auth';
    }
  });
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <><Outlet /></>;
};

export default ProtectedRoute;
