import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import AltText from './Language/AltText';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const NewVisitor = ({ id }) => {
  const [companyId, setCompanyId] = useState(id);

  useEffect(() => {
    setCompanyId(id);
  }, [id]);
  const token = localStorage.getItem('token');

  const [appointmentTime, setAppointmentTime] = React.useState('');
  const [visitorName, setVisitorName] = React.useState('');
  const [visitorCompany, setVisitorCompany] = React.useState('');
  const [visitorPurposeId, setVisitorPurposeId] = React.useState('');
  const [staffId, setStaffId] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const [openChild, setOpenChild] = React.useState(false);
  const [receptionNumber, setReceptionNumber] = React.useState('');

  const [appointmentType, setAppointmentType] = useState([]);
  const [companyUser, setCompanyUser] = useState([]);

  const path1 = `${process.env.REACT_APP_SERVER_URL}/visitor/visitor-type`;
  const path2 = `${process.env.REACT_APP_SERVER_URL}/user/${companyId}`;

  const headers = {
    Authorization: `${token}`,
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseChild = () => {
    setOpenChild(false);
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { plannedTime: appointmentTime, name: visitorName, companyName: visitorCompany, purposeOfVisitId: visitorPurposeId, staffUserId: staffId };

    const path = `${process.env.REACT_APP_SERVER_URL}/visitor/${companyId}`;
    axios.post(path, data, { headers })
      .then((response) => {
        setReceptionNumber(response.data.receptionNumber);
        setOpenChild(true);
      }).catch(() => {
        toast.error('Fill in all the fields');
      });
  };

  useEffect(() => {
    const appointmentTypes = async () => {
      try {
        await axios.get(path1, { headers }).then((res) => {
          setAppointmentType(res.data);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const companyUsers = async () => {
      try {
        await axios.get(path2, { headers }).then((res) => {
          setCompanyUser(res.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    appointmentTypes();
    companyUsers();
  }, [companyId]);

  return (
    <div>
      <button
        type="button"
        className="text-white py-2 px-4 rounded ml-2 mb-2 flex flex-row bg-[#1E4DB7] hover:bg-[#344873]"
        onClick={handleOpen}
      >
        {AltText('AddNewVisitor')}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, minWidth: 600 }}>
          <div className="flex flex-col items-center justify-center py-24 sm:px-6 lg:px-8">
            <div className="w-full max-w-md">
              <div>
                <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
                  {AltText('AddNewVisitor')}
                </h2>
              </div>
              <div className="mt-8">
                <div className="mt-6">
                  <form
                    className="space-y-6"
                    action="#"
                    method="POST"
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="rounded-md shadow-sm -space-y-px">
                      <div>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="datetime-local" className="font-semibold"> {AltText('AppointmentTime')}: </label>
                        <input
                          id="datetime-local"
                          name="datetime-local"
                          type="datetime-local"
                          autoComplete="datetime-local"
                          step="900"
                          required
                          onChange={(e) => {
                            setAppointmentTime(e.target.value);
                          }}
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2 mt-1"
                        />
                      </div>
                      <div>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="datetime-local" className="font-semibold"> {AltText('VisitorName')}: </label>
                        <input
                          id="visitor-name"
                          name="visitor-name"
                          type="text"
                          autoComplete="visitor-name"
                          required
                          onChange={(e) => {
                            setVisitorName(e.target.value);
                          }}
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2 mt-1"
                        />
                      </div>
                      <div>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="datetime-local" className="font-semibold"> {AltText('VisitorCompanyName')}: </label>
                        <input
                          id="company-name"
                          name="company-name"
                          type="text"
                          autoComplete="company-name"
                          required
                          onChange={(e) => {
                            setVisitorCompany(e.target.value);
                          }}
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2 mt-1"
                        />
                      </div>

                      <div>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="purpose" className="font-semibold"> {AltText('Purpose')}: </label>
                        <select onChange={(e) => { setVisitorPurposeId(e.target.value); }} id="purpose" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2 mt-1">
                          <option value="">
                            --{AltText('ChooseAnOption')}--
                          </option>
                          {
                            appointmentType.map((type) => (
                              <option value={type.id}>{AltText(type.name)}</option>
                            ))
                          }
                        </select>
                      </div>

                      <div>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="staff-name" className="font-semibold"> {AltText('StaffName')}: </label>
                        <select onChange={(e) => { setStaffId(e.target.value); }} id="staff-name" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2 mt-1">
                          <option value="">
                            --{AltText('ChooseAnOption')}--
                          </option>
                          {
                            companyUser.map((user) => (
                              <option value={user.id}>{user.name}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <button
                        type="submit"
                        className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#1E4DB7] hover:bg-[#2753b7] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {AltText('CreateNewAppointment')}
                      </button>
                      {/* eslint-disable-next-line react/button-has-type */}
                      <button
                        className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleClose}
                      >
                        {AltText('Close')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        hideBackdrop
        open={openChild}
        onClose={handleCloseChild}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, minWidth: 200 }}>
          <p>{AltText('NewAppointmentWasCreated')} <br /> {AltText('TheReceptionNumberIs')} <span style={{ fontWeight: 'bolder' }}>{receptionNumber}</span></p>
          <Button onClick={handleCloseChild}>{AltText('Close')}</Button>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default NewVisitor;
