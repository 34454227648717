import React, { useContext, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { LanguageContext } from '../../contexts/LanguageContext';

const LanguageButton = ({ lang }) => {
  const { language, toggleLanguage } = useContext(LanguageContext);
  useEffect(() => {
    if (language !== lang) {
      toggleLanguage();
    }
  }, [lang]);

  const handleLanguage = () => {
    if (language === 'english') {
      localStorage.setItem('language', 'japanese');
    } else {
      localStorage.setItem('language', 'english');
    }
    toggleLanguage();
  };

  return (
    <Tooltip title={<p style={{ fontSize: '12px' }}>Current Language</p>} arrow>
      <button type="button" className="bg-[#1E4DB7] hover:bg-[#344873] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-white font-bold py-2 px-4 mx-5 rounded" onClick={handleLanguage}> {language === 'english' ? 'Japanese' : 'English'} </button>
    </Tooltip>
  );
};

export default LanguageButton;
